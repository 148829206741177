.todo {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.sectionCumplimiento {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding-top: 5em;
}

.contenedorImages {
  position: relative;
  width: 600px;
  height: 400px;
  max-width: 100vw;
  border: 4px solid #111;
}

.contenedorImages .cajaImage {
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 100vw;
}

.contenedorImages .cajaImage:nth-child(1) {
  background: URL("../../images/cumplimiento.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.contenedorImages .cajaImage:nth-child(2) {
  background: URL("../../images/cumplimiento1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
  transition: 0.5s;
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
}

.contenedorImages .cajaImage:nth-child(2):hover {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.contenedorImages .cajaImage:nth-child(1):hover ~ .cajaImage:nth-child(2) {
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
}

.contenidoCumplimiento {
  width: 50%;
  padding: 0 100px 0 0;
  height: 400px;
}

.contenidoCumplimiento h1 {
  width: 100%;
  text-align: center;
  position: relative;
  top: 0;
  margin: 0;
}

.contenidoCumplimiento p {
  text-align: justify;
}

.contenidoCumplimiento .lead {
  width: 100%;
  padding: 20px;
  font-size: 16px;
  letter-spacing: 2px;
}

.contenidoCumplimiento ul {
  display: inline-block;
  width: 200px;
  margin: auto;
  padding: 0;
  background-color: teal;
  user-select: none;
  border-radius: 5px;
}

.contenidoCumplimiento .dropdown {
  margin-top: 50px;
}

.contenidoCumplimiento ul li {
  list-style: none;
  position: relative;
  height: 50px;
  padding: 10px 10px;
  transition: 0.5;
  box-sizing: border-box;
  color: white;
  text-align: center;
  cursor: pointer;
}

.contenidoCumplimiento ul li:hover {
  background-color: rgb(3, 167, 167);
}

.contenidoCumplimiento ul li ul {
  position: absolute;
  top: 49px;
  left: 0;
  display: block;
  background-color: teal;
  width: 290px;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.contenidoCumplimiento ul li ul li a {
  text-decoration: none;
  padding: 0 20px;
  color: white;
}

.contenidoCumplimiento ul li:hover ul {
  opacity: 1;
  visibility: visible;
}


@media (max-width: 1300px){
    .sectionCumplimiento {
        flex-direction: column;
    }
    .contenidoCumplimiento{
        width: 100%;
        max-width: 100vw;
        height: auto;
        padding: 0 0 150px 0 ;
    }
    .contenidoCumplimiento h1{
      font-size: 4em;
    }
    .contenidoCumplimiento p {
        max-width: 90%;
        margin: auto;
    }
    .contenidoCumplimiento .dropdown {
        margin-top: 50px;
      }
}
