.contenedor-selector {
  margin: 0;
  padding: 0;
  height: 90px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selector {
  background-color: white;
  height: 60px;
  width: 360px;
  border-radius: 10px;
  display: flex;
  margin: auto;
  position: absolute;
  overflow: hidden;
  border: solid 1px black;
}

input[type="radio"] {
  display: none;
}

.labelType1,
.labelType2 {
  display: block;
  height: 120px;
  width: 180px;
  background: linear-gradient(
    to bottom,
    white 0,
    white 60px,
    #2c2c2c 60px,
    #2c2c2c 180px
  );
  position: absolute;
  top: 0;
  color: #2c2c2c;
  transition: 0.5s;
}

.labelType2 {
  right: 0;
  border-left: solid 1px black;
}

.labelType1 span,
.labelType2 span {
  display: flex;
  height: 60px;
  width: 180px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 15px;
}

input:checked + label {
  background-position: 0 -60px;
  color: white;
  transition: 0.7s;
}
