.todoCertificados {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding-top: 5em;
}

.todoCertificados h1{
  text-align: center;
  border-radius: 20px;
  border-bottom: 5px solid #1097d6;
  border-left: 5px solid teal;
  width: 50%;
  margin: auto;
  padding: 0 0 20px 0;
}

.sectionCertificados {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 5em);
  background: #fff;
  /* margin-bottom: 80vh; */
}

.gridCertificados {
  position: relative;
  display: inline-grid;
  grid-template-columns: 33% 33% 33%;
  width: 80%;
  min-height: calc(100vh - 5em);
  padding-top: 3em;
  transition: all 0.5s;
}

.gridCertificados div {
  max-height: 100%;
  height: calc(80vh - 5em);
  width: 100%;
  padding: 50px;
  position: relative;
  display: flex;
  justify-content: center;
}

.cajaCertificado img {
  height: 100%;
  border: solid 2px black;
  transition: 0.4s;
  max-width: 100%;
}

.cajaCertificado img:hover {
  cursor: pointer;
  transform: scale(1.02);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.5);
}

.contenidoModal {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.imageModal {
  height: 100%;
  border: solid 3px black;
}

@media only screen and (max-height: 969px) {
  @media only screen and (max-width: 1620px) {
    .gridCertificados {
      grid-template-columns: 50% 50%;
    }
    .vacio {
      display: none !important;
    }
  }
  @media only screen and (max-width: 1080px) {
    .gridCertificados {
      grid-template-columns: 100%;
    }
    .cajaCertificado img {
      pointer-events: none;
    }
  }
}

@media only screen and (max-width: 1024px) {
  
  .gridCertificados {
    grid-template-columns: 100%;
  }
  .cajaCertificado img {
    pointer-events: none;
  }
  .vacio {
    display: none !important;
  }
}

@media only screen and (max-width: 500px){
  .todoCertificados h1{
    width: 90%;
    font-size: 3em;
  }
  .cajaCertificado img{
    max-width: 90vw;
  }
}
