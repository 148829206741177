.sectionValores{
    position: relative;
    min-height: 50vh;
    height: auto;
    background-color: white;
    display: flex;
    z-index: 5;
}

.sectionValores .cajaImagen{
    position: sticky;
    top: 0;
    min-width: 50%;
    height: 100vh;
}

.sectionValores .cajaImagen img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.sectionValores .contenido-sticky{
    padding: 100px 100px 70vh 100px;
    text-align: justify;
}

.sectionValores .contenido-sticky h2{
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    color: white;
    font-size: 3rem;
}

.sectionValores .contenido-sticky #integridad{
    -webkit-text-stroke: 2px rgb(15, 155, 202);
}

.sectionValores .contenido-sticky #espiritu{
    -webkit-text-stroke: 2px orange;
}

.sectionValores .contenido-sticky #diversidad{
    -webkit-text-stroke: 2px rgb(75, 175, 18);
}

.sectionValores .contenido-sticky #pasion{
    -webkit-text-stroke: 2px rgb(167, 11, 63);
}

.sectionValores .contenido-sticky #flexibilidad{
    -webkit-text-stroke: 2px rgb(107, 107, 107);
}

.sectionValores .contenido-sticky p{
    font-size: 1.5rem;
    letter-spacing: 2px;
}

/* @media only screen and (max-width: 574px){
    .sectionValores{
        display: block;
    }
    .sectionValores .cajaImagen{
        display: block;
        min-width: 100%;
        position: static;
        height: 50vh;
    }
    .sectionValores .cajaImagen img{
        position: relative;
        width: 100%;
    }
    .sectionValores .contenido-sticky{
        width: 90%;
        padding: 0;
        margin: auto;
    }
    .sectionValores .contenido-sticky h2{
        font-size: 4rem;
    }
} */

@media only screen and (max-width: 1024px){
    .sectionValores{
        display: block;
    }
    .sectionValores .cajaImagen{
        display: block;
        min-width: 100%;
        position: static;
        height: 50vh;
    }
    .sectionValores .cajaImagen img{
        position: relative;
        width: 100%;
    }
    .sectionValores .contenido-sticky{
        width: 90%;
        padding: 0;
        margin: auto;
    }
    .sectionValores .contenido-sticky h2{
        font-size: 2rem;
    }
    .sectionValores .contenido-sticky p{
        font-size: 1rem;
    }
}