/* .cuerpoVideo{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.contenedorVideo{
    width: 100vw;
    height: 100vh;
    background: #000;
} */

.section-video {
  position: relative;
  z-index: 10;
  min-width: 100vw;
  max-width: 100vw;
  height: 100vh;
  background: #000;
  margin-top: 5em;
}

.section-video .caja {
  position: absolute;
  top: calc(50% - 200px);
  left: calc(50% - 200px);
  width: 400px;
  height: 400px;
  transform-style: preserve-3d;
}

.section-video .caja div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transform: rotateX(-20deg) rotateY(25deg) translate3d(-75px, -50px, 100px);
}

.section-video .caja div span {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #ccc;
}

.section-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(20px);
}

.section-video .caja div span video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(0);
}

.section-video .caja div span:nth-child(1) {
  transform: rotateX(0deg) translate3d(0, 0, 200px);
}

.section-video .caja div span:nth-child(2) {
  transform: rotateY(90deg) translate3d(0, 0, -200px);
}

.section-video .caja div span:nth-child(2) video {
  transform: rotateY(180deg);
}

.section-video .caja div span:nth-child(3) {
  transform: rotateX(90deg) translate3d(0, 0, 200px);
}

@media only screen and (max-width: 500px) {
  .section-video .caja {
    width: 200px;
    height: 200px;
  }
  .section-video .caja div {
    transform: rotateX(-20deg) rotateY(25deg) translate3d(0px, 0px, 200px);
  }
  .section-video .caja div span:nth-child(1) {
    transform: rotateX(0deg) translate3d(0, 0, 100px);
  }
  .section-video .caja div span:nth-child(2) {
    transform: rotateY(90deg) translate3d(0, 0, -100px);
  }
  .section-video .caja div span:nth-child(2) video {
    transform: rotateY(180deg);
  }
  .section-video .caja div span:nth-child(3) {
    transform: rotateX(90deg) translate3d(0, 0, 100px);
  }
}
