#politicaDatos{
    z-index: 10;
    padding: 5em 0;
}

.indice{
    display: inline-block;
    width: 20px;
    margin-right: 10px;
    text-align: center;
}

.tituloDocumento{
    padding-top: 5em;
}

.tituloDocumento h3{
    font-size: 30px;
    text-align: center;
    margin-bottom: 3rem;
}

.tituloDocumento p{
    text-align: justify;
    margin: auto;
    margin-bottom: 10px;
    width: 60%;
    min-width: 300px;
}

.tablaPD{
    margin-top: 3em;
    box-shadow: 1px 1px 10px 1px black;
    max-width: 100vw;
}
.tablaPD thead tr {
    background-color: rgba(6, 94, 129, 0.65);
}
.tablaPD tr td, .tablaPD tr th{
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
}
.tablaPD tr td{
    text-align: center;
}