@import url("https://fonts.googleapis.com/css?family=Merriweather&display=swap");
@import url("https://fonts.googleapis.com/css?family=Josefin+Sans&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  width: 100vw;
}

.body-login {
  margin-top: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 5em);
  background: #131419;
}

.titulo-login {
  /* font-family: 'Merriweather', serif; */
  letter-spacing: 2em;
}

.row-form {
  margin-top: 5em;
  min-height: calc(100vh - 5em);
  max-height: calc(100vh - 5em);
  background: #131419;
}

.grid-imagenes {
  position: relative;
  display: inline-grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  height: calc(100vh - 5em);
  transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.grid-imagenes div {
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  position: relative;
}

.imagen {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: grayscale(100%);
  transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.imagen:hover {
  filter: grayscale(0%);
  transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

form {
  margin-top: 0;
}

.formulario-login {
  position: relative;
  margin: 10px auto 0;
  width: 80%;
  padding: 2% 2% 6%;
  background: #131419;
  border-radius: 10px;
  text-align: center;
  box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.05),
    -5px -5px 15px rgba(0, 0, 0, 0.05);
}

.formulario-login h2 {
  color: #c7c7c7;
  text-transform: uppercase;
  margin: 2% auto 0;
}

.formulario-login .input {
  text-align: left;
  margin-top: 10%;
}

.formulario-login .input .inputbox {
  margin-top: 5%;
}

.formulario-login .input .inputbox label {
  font-family: "Josefin Sans", sans-serif;
  display: block;
  color: #868686;
  margin-bottom: 10%;
  font-size: 18px;
}

.formulario-login .input .inputbox input {
  font-family: "Josefin Sans", sans-serif;
  width: 80%;
  height: 100%;
  background: #131419;
  border: none;
  outline: none;
  border-radius: 40px;
  padding: 5px 15px;
  color: #fff;
  font-size: 18px;
  color: #03a9f4;
  box-shadow: inset -2px -2px 6px rgba(255, 255, 255, 0.1),
    inset 2px 2px 6px rgba(0, 0, 0, 0.8);
}

.formulario-login .input .inputbox button {
  font-family: "Josefin Sans", sans-serif;
  width: 100%;
  height: 50px;
  background: #131419;
  border: none;
  outline: none;
  border-radius: 40px;
  padding: 5px 15px;
  color: #fff;
  font-size: 18px;
  color: #03a9f4;
  margin-top: 20px;
  box-shadow: -2px -2px 6px rgba(255, 255, 255, 0.1),
    2px 2px 6px rgba(0, 0, 0, 0.8);
}

.formulario-login .input .inputbox button:active {
  color: #006c9c;
  box-shadow: inset -2px -2px 6px rgba(255, 255, 255, 0.1),
    inset 2px 2px 6px rgba(0, 0, 0, 0.8);
}

.formulario-login .input .inputbox input::placeholder {
  color: #555;
  font-size: 18px;
}

.olvidada {
  font-family: "Dancing Script", cursive;
  margin-top: 0;
  color: #555;
}

.olvidada a {
  font-family: "Dancing Script", cursive;
  color: #ff0047;
}

@media only screen and (min-width: 768px) {
  .grid-imagenes {
    grid-template-columns: 33.3% 33.3% 33.3%;
  }
}

@media only screen and (min-width: 1040px) {
  .grid-imagenes {
    grid-template-columns: 25% 25% 25% 25%;
  }
  .formulario-login {
    max-height: calc(100vh - 7em);
  }
}
