.navPrincipal {
  min-height: 5em;
  position: fixed;
  top: 0;
  z-index: 100;
}

.navPrincipal div {
  position: fixed;
  top: 0;
  z-index: 100;
}

@media (max-width:500px){

  .navPrincipal, .navPrincipal div{
    max-width: 100vw;
  }
  .navPrincipal div ul{
    width: 100%;
  }
  #tituloPortal{
    margin: 10vh 0 0 -50px;
  }
}