.banner-tabla{
    background-image: url('https://images.freeimages.com/images/large-previews/02a/textures-1170046.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contenedor-pagina{
    margin-bottom: 80vh;
}



.contenedor-tabla{
    width: 60%;
    overflow-y: auto;
    max-height: 500px;
    background-color: white;
    box-shadow: 0 0 20px rgba(255, 255, 255, 1);
    border-radius: 20px;
    transition: all 0.4s;
}

.tituloCalidad{
    position: absolute;
    width: 100%;
    top: 2.5em;
    font-weight: bold;
    text-shadow: 0 0 20px white;
}

.tabla-calidad{
    /* position: relative;
    top: 0px; */
    background-color: white;
    border-collapse: collapse;
    font-size: 0.9em;
    width: 100%;
    min-height: 250px;
    box-shadow: 0 0 20px rgba(255, 255, 255, 1);
}

.tabla-calidad thead tr {
    background: #0E85A5;
    color: #fff;
    text-align: left;
    font-weight: bold;
}

.tabla-calidad th,
.tabla-calidad td{
    padding: 15px 20px;
}


.tabla-calidad tbody tr:hover {
    background-color: #0E85A5;
    color: white;
    font-weight: bold;
    transition: 0.4s ease;
}

@media only screen and (max-width: 574px){
    .contenedor-tabla{
        border-radius: 10px;
        width: 90%;
        max-height: 300px;
        transition: all 0.4s;
        
    }
    .tituloCalidad{
        top: 1.5em;
    }
}

@media only screen and (max-width: 1500px){
    .contenedor-tabla{
        border-radius: 10px;
        width: 70%;
        max-height: 400px;
        transition: all 0.4s;
    }
}