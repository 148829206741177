.navLateral{
    background-color: #fff;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    padding: 0 10px 0 10px;
    top: 5em;
    left: 0;
    height: 100vh;
    width: 70%;
    transform: translateX(-100%);
    transition: transform 0.4s ease;
}

#lateralActive{
    transform: translateX(0) !important;
}

.navLateral ul{
    padding: 0;
    list-style-type: none;
}

.navLateral ul li{
    margin: 14px 0;
}

.navLateral ul li a{
    color: black;
    text-decoration: none;
    text-transform: uppercase;
    transition: ease-in-out 1s;
}

.navLateral ul li a:hover{
    background-color: transparent;
    text-decoration: rgba(6, 94, 129, 0.7) underline;
}

.tooggle{
    border: none;
    padding: 0px 15px;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);
}

.tooggle:focus{
    background: #fff;
    outline: none;
}

.tooggle .iconAbrir{
    display: block;
}

.tooggle .iconCerrar{
    display: none;
}

#lateralActive .tooggle .iconCerrar{
    display: block;
}

#lateralActive .tooggle .iconAbrir{
    display: none;
}



@media only screen and (min-width: 574px){
    .navLateral{
        width: 60%;
        padding: 0 10px 0 10px;
    }
}

@media only screen and (min-width: 607px){
    .navLateral{
        width: 50%;
        padding: 0 10px 0 10px;
    }
}

@media only screen and (min-width: 756px){
    .navLateral{
        width: 40%;
        padding: 0 10px 0 10px;
    }
}

@media only screen and (min-width: 860px){
    .navLateral{
        width: 35%;
        padding: 0 10px 0 10px;
    }
}

@media only screen and (min-width: 1030px){
    .navLateral{
        width: 30%;
        padding: 0 10px 0 10px;
    }
} 

@media only screen and (min-width: 1266px){
    .navLateral{
        width: 25%;
        padding: 0 10px 0 10px;
    }
}

@media only screen and (min-width: 1666px){
    .navLateral{
        width: 20%;
    }
}