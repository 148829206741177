.contenedor-pagina {
  min-height: calc(100vh - 5em);
  margin-bottom: 80vh;
}

.tabs {
  height: 15em;
  padding-top: 5em;
  text-align: center;
  padding-bottom: 5em;
  background-color: #222;
}

.tab {
  outline: none !important;
  cursor: pointer !important;
  border: none !important;
  font-size: 16px !important;
  line-height: 24px !important;
  padding: 8px 16px !important;
  color: cadetblue !important;
  background-color: #222 !important;
  border: 1px solid #f1f1f1 !important;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1) !important;
  margin: 0 12px !important;
  font-weight: bold !important;

  transition: all 0.5s !important;
}

.tab:hover {
  background-color: black !important;
  color: white !important;
}

.tab:focus {
  background-color: black !important;
  color: white !important;
}

.panel {
  text-align: center;
  background-color: #222;
  min-height: calc(100vh - 20em);
  color: white;
}

.todo {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.secTarjetas-doc {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222;
  min-height: calc(100vh - 20em);
}

.secTarjetas-doc .contenedor {
  position: relative;
  display: flex;
  width: 1000px;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 5em;
  margin: 20px;
}

.secTarjetas-doc .contenedor .tarjeta {
  position: relative;
  height: 250px;
  background-color: #fff;
  display: flex;
  width: 45%;
  margin: 30px 0;
  background: #0e85a5;
}

.secTarjetas-doc .contenedor .tarjeta .imgBx {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s ease-in-out;
}

.secTarjetas-doc .contenedor .tarjeta:hover .imgBx {
  width: 150px;
  height: 150px;
  left: -75px;
  top: calc(50% - 70px);
  transition: 0.5s ease-in-out;
  background-color: #0e85a5;
  border: solid 5px #fff;
}

.secTarjetas-doc .contenedor .tarjeta .imgBx:before {
  content: attr(data-text);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 4em;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 700;
}

.secTarjetas-doc .contenedor .tarjeta .imgBx img {
  max-width: 150px;
  transition: 0.5s ease-in-out;
  opacity: 0.2;
}
.secTarjetas-doc .contenedor .tarjeta:hover .imgBx img {
  max-width: 70px;
  opacity: 1;
}

.secTarjetas-doc .contenedor .tarjeta .content {
  position: absolute;
  right: 0;
  width: calc(100%-75px);
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.secTarjetas-doc .contenedor .tarjeta .content h3 {
  margin-bottom: 5px;
  font-size: 25px;
}

.secTarjetas-doc .contenedor .tarjeta .content a {
  display: inline-block;
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #333;
  text-decoration: none;
  color: #fff;
  transition: 0.2s;
}

.secTarjetas-doc .contenedor .tarjeta .content a:hover {
  background-color: #444;
  transition: 0.4s ease-in-out;
}

@media (max-width: 992px) {
  .secTarjetas-doc .contenedor {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .secTarjetas-doc .contenedor .tarjeta {
    width: 400px;
  }
}

@media (max-width: 768px) {
  .secTarjetas-doc .contenedor .tarjeta {
    max-width: 300px;
    flex-direction: column;
    height: auto;
    background-color: #fff;
  }
  .secTarjetas-doc .contenedor .tarjeta .imgBx {
    position: relative;
  }
  .secTarjetas-doc .contenedor .tarjeta .imgBx:before {
    font-size: 2em;
  }
  .secTarjetas-doc .contenedor .tarjeta .imgBx,
  .secTarjetas-doc .contenedor .tarjeta:hover .imgBx {
    width: 100%;
    height: 200px;
    left: 0;
    border: none;
  }
  .secTarjetas-doc .contenedor .tarjeta .imgBx img,
  .secTarjetas-doc .contenedor .tarjeta:hover .imgBx img {
    max-width: 100px;
  }
  .secTarjetas-doc .contenedor .tarjeta .content {
    color: black;
    position: relative;
    width: 100%;
  }
  #frameIndicador{
    height: 250;
  }
}
