#contenedorCompleto{
    z-index: 10;
    background: #fff;
    margin-bottom: 80vh;
}

.todoFooter{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    z-index: -1;
}

footer{
    position: fixed;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #262626;
}

.contenidoFooter{
    width: 100%;
    height: 100%;
    position: relative;
    display: inline-grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    grid-template-rows: 1;
    align-items: center;
    text-align: center;
}

.contenidoFooter div h2{
    padding: 10px;
    display: inline-block;
    /* background: #0E85A5; */
    color: white;
    font-weight: bold;
}

.contenidoFooter div p{
    color: #13b4e0;
    font-size: 20px;
    width: 50%;
    margin: auto;
}

.contenidoFooter div img{
    width: 70%;
}

.contenidoFooter div ul{
    color: #12a0c7;
    font-size: 20px;
    width: 40%;
    margin: auto;
}

.contenidoFooter div ul li{
    text-align: left;
}

@media only screen and (max-width: 1370px){
    .contenidoFooter div p{
        width: 100%;
    }
    .contenidoFooter div ul{
        width: 60%;
    }
}

@media only screen and (max-width: 1024px){
    .contenidoFooter div p{
        width: 100%;
    }
    .contenidoFooter div ul{
        width: 80%;
    }
}

@media only screen and (max-width: 500px){
    .todoFooter{
        max-width: 100vw;
    }
    .contenidoFooter{
        grid-template-columns: 100%;
        overflow-y: scroll;
    }
    .contenidoFooter div ul{
        padding-bottom: 5em;
    }
    
}